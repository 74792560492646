import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { paddleReducer } from './paddle/slice';
// eslint-disable-next-line import/no-cycle
import { DispatchAction } from './types';

const rootReducer = combineReducers({
  paddle: paddleReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export const reduxStore = configureStore({
  reducer: rootReducer
});

export type AppDispatch = typeof reduxStore.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export const useDispatchAsync: () => DispatchAction = useAppDispatch;
