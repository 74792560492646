import { PaddlePrice, parsePaddlePrice } from './parsePaddlePrice';
import { PaddleSubscription, PaddleSubscriptionInputData, parsePaddleSubscription } from './parsePaddleSubscription';

export interface PaddleProduct {
  subscription: PaddleSubscription | undefined;
  listPrice: PaddlePrice;
  price: PaddlePrice;
  currency: string;
  title: string;
  id: string;
}

export type PaddleProductInputData = Record<string, string | PaddleSubscriptionInputData>;
export const parsePaddleProduct = (data: PaddleProductInputData): PaddleProduct => {
  return {
    subscription: data.subscription
      ? parsePaddleSubscription(data.subscription as PaddleSubscriptionInputData)
      : undefined,
    listPrice: parsePaddlePrice(data.list_price as Record<string, string>),
    price: parsePaddlePrice(data.price as Record<string, string>),
    currency: data.currency as string,
    title: data.product_title as string,
    id: data.product_id as string
  };
};
