export enum PaddleProductId {
  // Old Products
  Bundle = 510699,
  Android = 510653,
  iOS = 510652,
  Desktop = 504694,

  // New Products
  App = 584857,
  Lifetime = 633565,

  // Subscriptions
  QuarterlyPlan = 585869,
  YearlyPlan = 586830
}

export const allProductIds = [
  PaddleProductId.Bundle,
  PaddleProductId.Android,
  PaddleProductId.iOS,
  PaddleProductId.Desktop,
  PaddleProductId.App,
  PaddleProductId.Lifetime,
  PaddleProductId.QuarterlyPlan,
  PaddleProductId.YearlyPlan
];
