import React, { useEffect } from 'react';
import { NextComponentType } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { trackPageView, useStoreMarketingValues } from '../components/googleAnalytics';
import { nextAsset } from '../components/nextAsset';

declare global {
  interface Window {
    dataLayer: any | undefined;
    fbq: any | undefined;
    ttq: any | undefined;
    gtag: any | undefined;
    gTagID: string;
    mapkit: any;
    MusicKit: any;
    Paddle: any;
    trackConversion: any;
    plausible: any | undefined;
    onBlogCTAClicked: () => void;
  }
}

const { pageDescription, pageName } = defineMessages({
  pageDescription: {
    id: 'web.page.description',
    defaultMessage:
      'Transfer playlists between any music platform, including Spotify, Apple Music, Deezer, Qobuz, YouTube, and TIDAL. Seamlessly move your entire music library with just a few clicks. Start your easy music transfer today!'
  },
  pageName: {
    id: 'web.main_title',
    defaultMessage: 'FreeYourMusic: Ultra Simple Transfer of Playlists'
  }
});

const BaseComponent: React.FC<{
  Component: NextComponentType;
  pageProps: any;
}> = ({ Component, pageProps }) => {
  const intl = useIntl();
  const router = useRouter();
  const { locale } = router;
  useStoreMarketingValues();

  useEffect(() => {
    trackPageView();
  }, [router.asPath]);

  useEffect(() => {
    // <link rel="stylesheet" href="https://kit.fontawesome.com/c0a29c2951.css" crossOrigin="anonymous" />;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://kit.fontawesome.com/c0a29c2951.css';
    link.crossOrigin = 'anonymous';
    window.document.head.appendChild(link);
  }, []);

  const title = intl.formatMessage(pageName);
  const description = intl.formatMessage(pageDescription);
  const promoImg = nextAsset(`logos/promo-${locale}@2x.png`);

  /* eslint-disable react/no-danger */
  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-title" content="FreeYourMusic" />
        <meta name="mobile-web-app-capable" content="yes" />

        <meta itemProp="name" content={title} />
        <meta name="description" itemProp="description" content={description} key="description" />
        <meta itemProp="image" content={promoImg} key="image" />

        <meta property="twitter:site" content="@freeyourmusic" key="twitter-site" />
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="twitter:description" content={description} key="twitter-description" />
        <meta property="twitter:image" content={promoImg} key="twitter-image" />
        <meta property="twitter:image:width" content="2400" key="twitter-image-width" />
        <meta property="twitter:image:height" content="1200" key="twitter-image-height" />
        <meta property="twitter:card" content="summary_large_image" key="twitter-card" />

        <meta property="og:image" content={promoImg} key="fb-image" />
        <meta property="og:type" content="website" key="fb-type" />
        <meta property="og:url" content={router.asPath} key="fb-url" />
        <meta property="fb:app_id" content="1607923582824195" key="fb-app-id" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta property="og:description" content={description} key="fb-description" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              legalName: 'Hernas OÜ',
              url: 'https://freeyourmusic.com/',
              sameAs: ['https://twitter.com/freeyourmusic', 'https://www.facebook.com/freeyourmusicapp/'],
              email: 'support@freeyourmusic.com',
              brand: {
                '@type': 'Brand',
                logo: `https://freeyourmusic.com${nextAsset('logos/fym-logo-full-light@1024px.png', true)}`,
                name: 'FreeYourMusic',
                description
              }
            })
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'SoftwareApplication',
              operatingSystem: 'iOS,Android,Windows,Linux,macOS',
              name: 'Free Your Music',
              image: `https://freeyourmusic.com${nextAsset('logos/fym-logo-square-rounded@1024px.png', true)}`,
              url: 'https://freeyourmusic.com/',
              applicationCategory: 'https://schema.org/UtilitiesApplication',
              downloadUrl: 'https://freeyourmusic.com/download',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4.2',
                ratingCount: '28629'
              },
              offers: {
                '@type': 'Offer',
                price: '0'
              }
            })
          }}
        />
      </Head>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
      <svg
        style={{
          display: 'block',
          width: 0,
          height: 0,
          lineHeight: 0
        }}
      >
        <radialGradient id="fym-radial" cx="50%" cy="50%" r="97.597118%">
          <stop offset="0" stopColor="#fbd817" />
          <stop offset=".491316512" stopColor="#de376a" />
          <stop offset="1" stopColor="#6417fb" />
        </radialGradient>
      </svg>
    </>
  );
  /* eslint-enable react/no-danger */
};

export const Base = React.memo(BaseComponent);
