import { combineReducers, createAsyncThunk } from '@reduxjs/toolkit';
import { get, set } from 'local-storage';
import { PaddlePricesResponse, parsePaddlePricesResponse } from '../../paddle/models/PaddlePricesResponse';
import { fetchPrices } from '../../paddle/fetchPrices';
import { allProductIds } from '../../paddle';
import { ApiState, createApiSlice } from '../createApiSlice';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../configureStore';

export const paddleResponseLocalStorageKey = 'paddleFymResponseLocalStorage2022.12.05';

// eslint-disable-next-line @typescript-eslint/ban-types
export const fetchPaddle = createAsyncThunk<PaddlePricesResponse, void>('paddlePrice', async () => {
  const paddleResponseLS = get<any | undefined | null>(paddleResponseLocalStorageKey);
  if (paddleResponseLS) {
    return parsePaddlePricesResponse(paddleResponseLS);
  }

  const paddlePricing = (await fetchPrices(allProductIds)) as any;
  const pricesResponse = parsePaddlePricesResponse(paddlePricing);
  set(paddleResponseLocalStorageKey, paddlePricing);
  return pricesResponse;
});

const paddlePricesSlice = createApiSlice<PaddlePricesResponse, void>('paddlePrices', fetchPaddle);

export const paddleReducer = combineReducers({
  prices: paddlePricesSlice.reducer
});

export const paddlePricesSelector = (state: RootState): ApiState<PaddlePricesResponse> => state.paddle.prices;
