import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import { withIntlApp } from '@moxy/next-intl';
import Head from 'next/head';
import Script from 'next/script';
import { Rubik } from 'next/font/google';
import { Base } from '../src/layouts/BaseComponent';
import { reduxStore } from '../src/redux/configureStore';
import '../src/assets/styles/main.scss';
import { config } from '../src/config/config';
import { ConfigEnvironment } from '../src/config/ConfigType';
import { IsPreviewContext } from '../src/utils/IsPreviewContext';

const mainFont = Rubik({
  subsets: ['latin'],
  display: 'swap',
  weight: ['300', '400', '500', '700'],
  variable: '--font-main'
});

class MyApp extends App {
  render(): JSX.Element {
    /* eslint-disable react/no-danger */
    return (
      <Provider store={reduxStore}>
        <Head>
          <meta name="viewport" key="viewport" content="width=device-width, initial-scale=1" />

          <script
            dangerouslySetInnerHTML={{
              __html:
                `window.gTagID = "${config.gTagID}"\n` +
                'window.dataLayer = window.dataLayer || [];\n' +
                'function gtag(){dataLayer.push(arguments);}\n' +
                "gtag('js', new Date());"
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`
            }}
          />
        </Head>

        {/* eslint-disable-next-line react/no-unknown-property */}
        <style jsx global>
          {`
            :root {
              --main-font: ${mainFont.style.fontFamily};
            }
          `}
        </style>
        {config.env === ConfigEnvironment.PRODUCTION ? (
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${config.gTagID}`} strategy="afterInteractive" />
        ) : null}
        {config.plausible && (
          <Script
            src={`${config.plausible.url}js/script.manual.js`}
            strategy="afterInteractive"
            data-domain={config.plausible.siteId}
          />
        )}

        <IsPreviewContext.Provider value={false}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Base {...this.props} Component={this.props.Component} pageProps={this.props.pageProps} />
        </IsPreviewContext.Provider>
      </Provider>
    );
  }
}

const loadLocale = async (locale = 'en') => {
  const module = await import(/* webpackChunkName: "intl-messages" */ `../src/lang/${locale}.json`);
  return module.default;
};

// eslint-disable-next-line import/no-default-export
export default withIntlApp(loadLocale)(MyApp);
