import { PaddlePrice, parsePaddlePrice } from './parsePaddlePrice';

export type PaddleSubscriptionInputData = Record<string, string | number | Record<string, string>>;

export interface PaddleSubscription {
  listPrice: PaddlePrice;
  price: PaddlePrice;
  months: number;
  frequency: number;
  interval: string;
  trialDays: number;
}
export const parsePaddleSubscription = (data: PaddleSubscriptionInputData): PaddleSubscription => {
  const frequency = data.frequency as number;
  const interval = data.interval as string;
  return {
    listPrice: parsePaddlePrice(data.list_price as Record<string, string>),
    price: parsePaddlePrice(data.price as Record<string, string>),
    months: interval === 'year' ? frequency * 12 : frequency,
    frequency,
    interval,
    trialDays: data.trial_days as number
  };
};
