import { PaddleProduct, PaddleProductInputData, parsePaddleProduct } from './parsePaddleProduct';

export interface PaddlePricesResponseInputData {
  response: {
    products: PaddleProductInputData[];
  };
}

export interface PaddlePricesResponse {
  products: PaddleProduct[];
  productsById: Record<string, PaddleProduct>;
  data: PaddlePricesResponseInputData;
}

export const parsePaddlePricesResponse = (data: PaddlePricesResponseInputData) => {
  const productsById: Record<string, any> = {};
  const products = data.response.products.map((p) => parsePaddleProduct(p));
  products.forEach((p: any) => {
    productsById[p.id] = p;
  });
  return {
    products,
    productsById,
    data
  };
};
