import URL from 'url-parse';
import qs from 'qs';
import { config } from '../config/config';

// eslint-disable-next-line no-underscore-dangle
function _nextAsset(imgixHost: string, urlPath: string, imgixParams = {}): string {
  const parsedUrl = URL(`https://${imgixHost}/${urlPath}`, (query: string) =>
    qs.parse(query, { ignoreQueryPrefix: true })
  );
  parsedUrl.set('query', {
    ...(parsedUrl.query as any),
    auto: 'compress,format',
    fit: 'fillmax',
    ch: 'Save-Data',
    ...imgixParams
  });
  parsedUrl.set('query', {
    ...(parsedUrl.query as any)
  });
  return `${parsedUrl}`;
}
export function nextDynamicAsset(urlPath: string, imgixParams = {}, skipImgix = false): string {
  if (!config.imgixDynamicHost || skipImgix) {
    return `${config.domain}/api/meta-image/${urlPath}`;
  }
  return _nextAsset(config.imgixDynamicHost, urlPath, imgixParams);
}

export function nextAsset(src: string, skipImgix = false, imgixParams = {}): string {
  if (config.imgixHost && !skipImgix && (src.endsWith('.png') || src.endsWith('.jpg') || src.endsWith('.jpeg'))) {
    return _nextAsset(config.imgixHost, `${src}`, imgixParams);
  }
  return `/statics/${src}`;
}
