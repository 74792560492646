import Big from 'big.js';

export interface PaddlePrice {
  gross: string;
  net: string;
  tax: string;
}

export const parsePaddlePrice = (data: Record<string, string>): PaddlePrice => {
  return {
    gross: new Big(data.gross).toString(),
    net: new Big(data.net).toString(),
    tax: new Big(data.tax).toString()
  };
};
